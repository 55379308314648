<template>
  <!--创建地图容器元素-->
  <div>
<!--    <van-nav-bar left-arrow @click-left="$router.go(-1)" title="放映点详情">-->
<!--    </van-nav-bar>-->
    <div >
      <!-- {{ pointInfo }} -->
      <van-cell >
        <template #title>
          <b>{{ pointInfo.name }}</b>
        </template>
        <template #label>
          <!-- <span>{{ pointInfo.address }}</span> -->
          <van-button type="info" size="mini"  plain round>
            {{ pointInfo.showPointType==1?'固定放映点':'流动放映点' }}
          </van-button>
        </template>
      </van-cell>
      <van-cell title="单元格"  label="描述内容" >
        <template #title>
          <div>
          <van-icon name="location" color="#1492FF"/>
          {{ pointInfo.address }}
          </div>
          <!-- <div style="font-size: 12px; margin-left: 14px;color: #1492FF;">
           {{ pointInfo.showPointType==1?'固定放映点':'流动放映点' }}
          </div> -->
        </template>
        <template #label>
          <span>
            <van-icon name="phone" color="#1492FF"/> {{ pointInfo.phone||'暂无' }}
          </span>
        </template>
      </van-cell>
      <div class="plan">
        <span>放映计划</span>
        <span @click="showDate = true">
         <van-icon name="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d1e8e54eb.png"/>&nbsp; {{ params | queryPlanTime }}
        </span>
      </div>
    </div>
    <div v-if="pointInfo.schedules.length">
      <div class="schedule" v-for="(schedule, index) in pointInfo.schedules" :key="index">
        <div class="schedule-time">
          {{schedule.playDateTime.split(' ')[0]  }}
        </div>
        <div class="schedule-cinema">
          <div class="schedule-cinema-time">
            <div>
              {{ schedule.playDateTime.split(' ')[1] | formatTime}}
            </div>
            <div>
              <van-button type="info" :disabled="schedule.status>0" round size="small" block @click="addMyWantSee(schedule)"> {{ schedule.status?'已加入':'我想看' }}</van-button>
            </div>
          </div>
          <div class="schedule-cinema-films">
            <div v-for="film in schedule.films" :key="film.id" class="schedule-cinema-film">
              <div >
                <van-image :show="false" :src="film.filmPicture"  width="60" height="84" fit="cover" />
              </div>
              <div style="margin-left: 5px;width: 15vw;">
                <span>{{ film.name }}</span>
                <span style="color: #999; font-size: 12px;">{{ film.filmLength }}分钟</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <pre>
        {{ pointInfo.schedules }}
      </pre> -->
    </div>
    <div v-else>
      <van-empty description="暂无放映计划"/>
    </div>
    <van-calendar v-model="showDate" color="#1492FF" :default-date="null" :first-day-of-week="1" type="range" @confirm="confirmDate" />
  </div>
</template>
<script>
import { api } from '@/api'
import { Dialog } from 'vant'
import { SetPvAndUv } from '@/mixins/PvAndUv'

export default {
  name: 'TdtMap',
  mixins: [SetPvAndUv],
  data() {
    return {
      pointId: null,
      pointInfo: {
        schedules: [],
      },
      showDate:false,
      // 中心点
      centerPoint: {
        lng: 119.9201,
        lat: 31.068228,
        name: '后洋村',
      },
      mapData: {
        regionArea: {
          lng: 119.9201,
          name: '后洋村',
          id: 100408,
          lat: 31.068228,
        },
        lng: 119.9201,
        surrounds: [],
        lat: 31.068228,
      },
      params: {
        id: null,
        startDate: null,
        endDate: null,
      },
    }
  },
  created() {
    this.params.startDate = this.moment().format('YYYY-MM-DD')
    this.params.endDate = this.moment().add(1, 'month').format('YYYY-MM-DD')
    this.pointId = this.$route.params.id
    if (this.pointId) {
      this.params.id = this.pointId
      this.getPointInfo()
    } else {
      Dialog.alert({
        message: '请选择放映点',
        title: '提示',
      })
      this.$router.go(-1)
    }
  },
  mounted() {

    // this.getPointInfo()
  },
  watch: {},
  methods: {
    // 获取放映点详情
    async getPointInfo() {
      const { code, result, success } = await api.getShowPointDetail({
        ...this.params,
      })
      if (success) {
        this.pointInfo = result
      } else {
        Dialog.alert({
          message: result.message,
          title: '提示',
        })
        this.$router.go(-1)
      }
    },
    confirmDate(date) {
      console.log(date);
      this.params.startDate = this.moment(date[0]).format('YYYY-MM-DD')
      this.params.endDate = this.moment(date[1]).format('YYYY-MM-DD')
      this.getPointInfo()
      this.showDate = false
    },
    //TODO 加入我想看
    async addMyWantSee(schedule) {
      const {  result, success,message } = await api.personal.getPersonalWantSee({
        planId: schedule.id,
        filmIds: schedule.films.map(film => film.id),
      })
      if (success) {
        Dialog.alert({
          message: '已成功加入我想看',
          title: '提示',
        })
        schedule.status = 1
      } else {
        Dialog.alert({
          message,
          title: '提示',
        })
      }
    }
  },
  filters: {
    // 查询计划时间
    queryPlanTime(params) {
      const { startDate, endDate } = params
      // 2024年01月01日 -12月31日
      const year = startDate.slice(0, 4)
      const month = startDate.slice(5, 7)
      const day = startDate.slice(8, 10)
      const endYear = endDate.slice(0, 4)
      const endMonth = endDate.slice(5, 7)
      const endDay = endDate.slice(8, 10)
      const start = `${year}年${month}月${day}日`
      const end = `${year===endYear?'':endYear+'年'}${endMonth}月${endDay}日`
      return `${start} - ${end}`
    },
    // 19:00:00 -> 晚上 5:00
    formatTime(time) {
      const hour = time.slice(0, 2)
      const minute = time.slice(3, 5)
      const second = time.slice(6, 8)
      const ampm = ()=>{
        if(hour >= 6 && hour < 12){
          return '上午'
        }else if(hour >= 12 && hour < 18){
          return '下午'
        }else{
          return '晚上'
        }
      }
      const hourStr = hour % 12 || 12
      return ` ${ampm()} ${hourStr}:${minute}`
    },

  },
}
</script>

<style scoped lang="less">
.searchInput {
  width: 70vw;
  height: 44px;
  background: #ffffff91;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  padding: 10px 12px;
}

.cinemaList {
  margin-top: 10px;
  padding: 0 10px;
  max-height: 50vh;
  overflow-y: auto;
}
.plan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 10px auto;
  font-size: 14px;
  width: 95%;
  font-size: @font-size-large;
  span:nth-child(2){
    cursor: pointer;
    color: @color-primary;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.schedule{
  width: 90vw;
  margin: 10px 0 10px auto;
  &-time{
    font-size: @font-size-large;
    font-weight: bold;
    color: @color-primary;
    position: relative;
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: linear-gradient( 180deg, #5EDAE3 0%, #6F60F4 100%);
    }
  }
  &-cinema{
    position: relative;
    padding:10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &-time{
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &-films{
      width: 75%;
      overflow: auto;
      display: flex;
    }
    &-film{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 10px;
      width: 165px;
      margin-right: 5px;
      height: 100px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid #E6E8EB;
      padding: 6px;
      div{
        &:first-child{
          &>img{
            border-radius: 6px !important;
          }
        }
        &:nth-child(2){
          display: flex;
          font-size: @font-size-small;
          flex-direction: column;
        }
      }
    }
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 100%;
      background: #E8E9EC;
    }
  }
}

::v-deep .van-nav-bar--fixed {
  top: 2vh;
  background-color: transparent;
}

::v-deep .van-nav-bar__title {
  max-width: 70%;
}
</style>
